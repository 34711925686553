angular.module("app")
    .directive("cLength", function ($rootScope, _mapFieldService, _view, gettextCatalog, _logicService) {
        return {
            restrict: "E",
            template: "{{x}}<small>{{units}}</small>",
            scope: {
                value: '@value',
                precision: '@precision'
            },
            link: function ($scope) {
                if ($scope.value == 'n/a') {
                    $scope.x = gettextCatalog.getString('n/a');
                } else {
                    if (_.isUndefined($scope.precision)) {
                        $scope.precision = 0;
                    }
                    if (_.isUndefined($scope.unit)) { //can me mm or m
                        $scope.unit = 'mm';
                    }
                    if (_view.getCurrentUser().units == "IMPERIAL") {
                        if ($scope.unit == 'mm') {
                            $scope.x = _logicService.round($scope.value * 0.03937, $scope.precision); //mm to in
                            $scope.units = gettextCatalog.getString('in');
                        } else {
                            $scope.x = _logicService.round($scope.value * 3.2808, $scope.precision); //m to foot
                            $scope.units = gettextCatalog.getString('ft');
                        }
                    } else {
                        $scope.x = _logicService.round($scope.value, $scope.precision);
                        if ($scope.unit == 'mm') {
                            $scope.units = gettextCatalog.getString('mm');
                        } else {
                            $scope.units = gettextCatalog.getString('m');
                        }
                    }
                }
            }
        }
    });